.upper-menu-container {
  display: flex;
  align-items: flex-end;
  height: 100%;

  .menu-item {
    display: flex;
    align-items: center;
    padding: 10px 25px;
    font: $header-2 $font-medium;
    text-decoration: none;
    color: rgba(40, 57, 133, 0.5);

    .material-icons-outlined {
      font-size: $header-1;
    }

    &:hover {
      color: $primary-color;
    }
  }

  .menu-item.active {
    color: $primary-color;
    background-color: $secondary-color;
    border-radius: 10px 10px 0 0;
    opacity: 1;
  }
}

.header-icons {
  margin-right: 10px;
}

.dashboard-desktop-logo {
  display: flex;
  margin: auto;
  padding: 0 5%;
}

.dashboard-mobile-logo {
  display: flex;
  margin: auto;
  padding: 0 5%;
  height: 50%;
}

@media only screen and (max-width: 680px) {
  .header-icons {
    margin-right: 0;
  }

  .upper-menu-container {
    .menu-item {
      padding: 10px 15px;
    }
  }
}
