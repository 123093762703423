.quick-access-popup {
  position: fixed;
  right: 0;
  bottom: 100px;
  top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: right 1s cubic-bezier(0.82, 0.085, 0.395, 0.895);
  z-index: 1000;

  .arrow {
    position: absolute;
    left: -44px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $secondary-color;
    color: $primary-color;
    border-radius: 10px 0 0 10px;
  }

  .arrow * {
    cursor: default;
  }

  .container {
    background-color: $white-color;
    box-shadow: 0 0 8px #999;
    border-radius: 10px 0 0 10px;
    max-height: 100%;
    overflow: auto;
    width: 280px;

    .header-title {
      padding: 10px 20px;
      font: $header-3 $font-medium;
      color: $primary-color;
      border-bottom: 1px solid $secondary-color;
    }

    .content {
      padding: 20px;
      background-color: $white-color;
      min-height: 100px;
    }
  }

  &.inactive {
    right: -280px;
  }

  &.active {
    right: 0;
  }
}

@media only screen and (max-width: 400px) {
  .quick-access-popup {
    .container {
      height: 100%;
    }
  }
}
