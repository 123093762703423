.application-container {
  width: 100%;
  height: calc(var(--app-height) - 130px);
}

.application-tool-form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  width: 100%;
  margin: 0 auto;
  padding: 20px 10%;

  .application-tool-form-content {
    width: 100%;
  }
}

.movetoSidebar {
  padding: 20px 5% 150px 5% !important;
}

.application-form-heading {
  color: $primary-color;
  font: $header-3 $font-medium;
  margin: 20px auto;
}

.gridcolchange {
  grid-column-start: 1;
  grid-column-end: 3;
}

.application-form-subheading {
  color: $primary-color;
  font: $header-3 $font-medium;
  margin-top: 0px;
  grid-column-start: 1;
  grid-column-end: 3;
}

.centerAlign {
  text-align: center;
  margin: 20px auto;
}

.small_width {
  width: 100%;
}

.width-50 {
  width: 50%;
  margin: 0 auto;
}

.regostate {
  .answer-custom-select {
    width: 100%;
    margin: 20px auto;
  }
}

.application-tool-form-inner {
  display: flex;
  //grid-template-columns:100%;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  margin: 18px 0;

  .question {
    text-align: left;
    margin: 5px auto 5px;
  }

  .answer-custom-select {
    width: 100%;
  }

  .suggestion {
    color: $border-color;
    text-align: left;
    font: $foot-note $font-regular;
    max-width: 700px;
    margin: 5px auto 5px;
  }
}

.application-tool-form-inner.noGrid {
  display: block !important;

  .suggestion {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    text-align: center;
  }

  .sigCanvas {
    width: 100%;
    max-width: 500px;
    border: 1px solid $primary-color;
  }
}

.application-error-message {
  color: $error-color;
  font: $foot-note $font-regular;
  margin: 10px 0;
  width: 100%;
  white-space: normal;
  word-wrap: break-word;
}

// .signature-error-message {
//   color: $error-color;
// }

@media only screen and (min-width: 680px) {
  .application-container {
    height: calc(var(--app-height) - 185px);
  }

  .application-tool-form {
    .application-tool-form-content {
      width: 100%;
      max-width: 100%;
    }
  }

  .application-tool-form-inner {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .application-form-heading {
    font: $header-1 $font-medium;
    margin: 24px 0;
  }
}

.broker-app-form {
  height: calc(100% - 160px);
  padding: 20px 5% !important;
}

@media only screen and (min-width: 1260px) {
  .application-tool-form {
    .application-tool-form-content {
      display: grid;
      width: 100%;
      max-width: 1024px;
    }
  }
}
