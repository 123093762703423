.page-not-found {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10vh;

  div {
    margin-top: 20px;
    font: $body-text $font-medium;
    color: $primary-color;
  }

  img {
    max-height: 200px;
    height: 20vh;
  }

  a {
    color: $primary-color;
  }
}

.button-row {
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  .button {
    height: 40px;
  }
}

.quote-tool-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 25px;

  .breadcrumb {
    padding: 0;
  }
}

.breadcrumb {
  display: flex;
  align-items: center;
  font: $body-text $font-medium;
  color: rgba(40, 57, 133, 0.9);
  padding: 10px 25px;
  white-space: nowrap;
  overflow-y: auto;

  > span:first-child:hover {
    color: $primary-color;
    cursor: pointer;
  }

  > span:last-child {
    color: $field-color;
    cursor: text;
    opacity: 0.5;
  }
}

.breadcrumb::-webkit-scrollbar {
  display: none;
}

.width-small .material-icons-outlined {
  z-index: 999;
  cursor: pointer !important;
  margin-right: 5px;
}

.disabledfield {
  .custom-select {
    cursor: default;
  }
  input {
    pointer-events: none;
  }
}

.hide-arrow {
  .material-icons-outlined {
    display: none;
  }

  .just-bet {
    justify-content: center !important;
    cursor: default;

    .selected-first-value {
      padding-left: 0;
    }
  }
}

.dot {
  height: 35px;
  width: 35px;
  font: $body-text $font-medium;
  background-color: $secondary-color;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  line-height: 35px;
}

@media only screen and (min-width: 680px) {
  .breadcrumb {
    font: $header-3 $font-medium;
  }
}

@media only screen and (max-width: 680px) {
  .breadcrumb {
    font: $foot-note $font-medium;
    padding: 10px 5px;
  }
}
