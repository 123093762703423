.file-drag {
  margin: 5px 0 5px 0;

  cursor: default;
  color: $primary-color;
  border: 2px solid $primary-color;
  text-align: center;
  border-radius: 5px;
  .input-wrapper {
    padding: 1em;
    min-height: 100px;
    margin-top: 1em;
  }
}

.file-upload-label {
  font: $body-text $font-regular;
  min-height: 40px;
}

.previews {
  font: $foot-note $font-regular;
  display: flex;
  flex-direction: column;
  gap: 5px;
  word-break: break-all;
}

.w100 {
  width: 100px;
}

.btn-remove {
  background: $primary-color;
  border: none;
  color: $white-color;
  cursor: pointer;
  margin-right: 10px;
}
