.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.25);
  background-blend-mode: screen;
  z-index: 10;
}

.modal-content {
  min-width: 400px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
}

.modal-header {
  display: flex;
  align-items: center;
  padding: 20px 30px;
  font: $header-1 $font-medium;
  color: $primary-color;
  border-bottom: 1px solid $border-color;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.modal-body {
  padding: 20px 30px 0 30px;
  max-height: calc(80vh - 100px);
  background-color: white;
  overflow-y: auto;
  //tr:hover {background-color: $background-color}

  table {
    th {
      text-align: left;
    }
  }
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 20px 30px;
}
.equalWidthButton {
  justify-content: flex-center !important;
  .button {
    width: 100% !important;
  }
}
.modal-footer-buttons {
  margin-left: 10px;
}

.confirmation-modal {
  max-width: 500px;
  width: 100%;
}
@media only screen and (max-width: 680px) {
  .modal-header {
    font: $header-3 $font-medium;
  }
  .modal-content {
    min-width: 90vw;
  }
}

@media only screen and (max-width: 450px) {
  .modal-content {
    width: 90%;
  }
  .modal-body {
    justify-content: flex-start;
  }
}
