.multiple-questions,
.repeater-questions {
  grid-column: 1/3;

  .application-tool-form-inner {
    display: flex;
    flex-direction: column;
    //grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    width: 100%;
    margin: 8px 0;

    .suggestion {
      clear: both;
    }
  }

  .title {
    font: $body-text $font-medium;
    margin-bottom: 16px;
  }
}

.form-checkbox-question {
  place-content: flex-start;
  display: grid;
  align-content: center;
  grid-column-start: 1;
  grid-column-end: 3;
  margin-bottom: 8px;

  .d-flex {
    margin-top: 5px;
    display: flex !important;
    flex-wrap: wrap;
    gap: 5px;
  }

  .suggestion {
    padding-left: 25px;
  }
}

.form-googlelookup-question,
.form-googlelookup-questionNew {
  grid-column-start: 1;
  grid-column-end: 3;
}

.form-streetaddress-question {
  grid-column-start: 3;
  grid-column-end: 5;
}

.movetoSidebar .form-googlelookup-question {
  grid-column-start: 1;
  grid-column-end: 2;
}

.movetoSidebar .form-streetaddress-question {
  grid-column-start: 2;
  grid-column-end: 3;
}

.previous-employer-start-date {
  grid-column-start: 1;
}
.land-lord-real-estate {
  grid-column-start: 1;
}
.adjusted_surplus_deficit {
  grid-column-start: 2;
  grid-row-start: 15;
}

.material-icons-outlined {
  cursor: pointer;
  float: left;
  // margin: 1.5% 1%;
}

.width-small {
  width: 100% !important;
  float: left;
  filter: alpha(opacity=50);
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  opacity: 0.5;
}
.document-notes {
  grid-row: 5 !important;
}

// .signature-wrapper {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   gap: 15px;
// }

// .signature-buttons {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   gap: 15px;
//   flex-wrap: wrap;
// }

@media only screen and (min-width: 680px) {
  .multiple-questions,
  .repeater-questions {
    .application-tool-form-inner {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }

    .title {
      font: $header-3 $font-medium;
    }
  }
}
