.question {
  // margin-bottom: 20px;
  text-align: center;
  color: $primary-color;

  .question_text {
    font: $header-1 $font-medium;
    margin: 24px auto;
  }

  .description {
    text-align: center;
    font: $header-3 $font-medium;
    max-width: 700px;
    margin: 15px auto;
  }
}

.question-text-multi {
  font: $header-1 $font-medium !important;
  margin: 24px auto !important;
}

.suggestion {
  color: $border-color;
  text-align: center;
  font: $header-3 $font-regular;
  max-width: 700px;
  margin: 15px auto 0;
}

@media only screen and (max-width: 680px) {
  .question .question_text,
  .question .question-text-multi {
    font: $header-3 $font-medium;
    margin: 20px auto;
  }
}

.tooltip {
  position: relative;
}

.tooltip:hover:after {
  background: $secondary-color;
  border-radius: 0.5em;
  bottom: 37px;
  color: $primary-color;
  content: attr(toolTipTitle);
  display: block;
  font-size: $body-text;
  left: 40px;
  padding: 0.3em 1em;
  position: absolute;
  max-width: 500px;
  white-space: pre-line;
  z-index: 98;
}

.tooltip:hover:before {
  border: solid;
  border-color: $secondary-color transparent;
  border-color: $secondary-color transparent;
  border-width: 0.4em 0.4em 0 0.4em;
  bottom: 28px;
  content: "";
  display: block;
  left: 55px;
  position: absolute;
  z-index: 99;
}
@media only screen and (max-width: 680px) {
  .tooltip:hover:after {
    bottom: 55px;
    font-size: $foot-note;
  }
  .tooltip:hover:before {
    bottom: 48px;
  }
}
