.accordion-item {
  margin-bottom: 8px;
}

.accordion-item:last-child {
  margin-bottom: 0;
}

.accordion-item-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $primary-color;
  padding: 8px 10px;
  background: $secondary-background-color;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;

  span {
    font-size: 24px;
    font-weight: 500;
    transition: transform 0.3s ease-in-out;
  }

  label {
    margin-left: 10px;
    font: $body-text $font-medium;
  }
}

.active-accordion-header {
  background-color: $primary-color;
  color: $white-color;
  border-radius: 8px 8px 0 0;
}

.accordion-body-container {
  max-height: 0;
  background-color: $white-color;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  border: 1px solid $primary-color;
  border-top: none;
  visibility: hidden;
  border-radius: 0 0 8px 8px;

  > *:first-child {
    padding: 8px 49px 8px 49px;
  }

  .quote-inner {
    margin-top: 12px;

    h1 {
      color: $primary-color;
      font-size: $header-1;
      font-family: $font-regular;
    }

    .quote-question {
      color: $black-color;
      font-size: $body-text;
      width: 100%;
    }

    .quote-answer {
      font-size: $foot-note;
      color: $field-color;
      width: 100%;
    }
  }
}

.active-accordion {
  overflow: unset !important;
  max-height: 2000px;
  visibility: initial;
}

.rotate-icon {
  transform: rotate(180deg);
}

.accordion-item-count {
  margin-left: 10px;
  padding: 5px;
  font-size: $font-regular !important;
  color: white;
  background-color: $primary-color;
  border-radius: 5px;
}

.applicationsingle {
  .quote-answer {
    margin-bottom: 2%;
  }

  .active-accordion {
    max-height: 100% !important;
  }
}
