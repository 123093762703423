.repeater-wrapper {
  padding: 10px;
  border: 2px solid $background-color;
  border-radius: 10px;

  .application-tool-form-inner {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .no-entry {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: $primary-color;
    font-size: $header-3;

    > span {
      cursor: pointer;
    }
  }

  .repeater-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $primary-color;
    font: $header-2 $font-medium;
    padding: 10px;

    .repeater-add-icon {
      color: $success-color;
      font-size: $header-1;
      cursor: pointer;
    }
  }

  .repeater-table {
    width: 100%;
    margin: 20px 0;
    display: block;
    overflow-x: auto;

    .repeater-rows {
      display: grid;
      // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      grid-template-columns: repeat(5, 1fr);
      margin: 10px 0;
      position: relative;
      padding-right: 60px;
    }

    th {
      padding: 10px 5px;
      font-size: $header-3;
      color: $field-color;
      text-align: left;
      min-width: 100px;

      &:first-child {
        min-width: 50px;
      }
    }

    tr > td {
      padding: 10px 5px;

      .repeater-remove-icon {
        color: $error-color;
        font-size: $header-1;
        cursor: pointer;
      }
    }

    .repeater-remove-cell {
      grid-column-end: -1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    td:last-child {
      width: 100%;
    }
  }
}

.repeater-select {
  min-width: 150px;
}
.repeater_btns_wrapper * {
  font-size: 1.5rem !important;
}

.repeater_btns_wrapper {
  position: absolute;
  top: 18px;
  right: 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.repeater-add-icon {
  color: #42ba96;
}

@media only screen and (min-width: 680px) {
  .repeater-wrapper {
    padding: 25px 15px;
  }
}
