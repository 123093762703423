.dashboard-grey-card-container {
  display: flex;
  margin: 15px 0 30px 0;
  flex-wrap: wrap;
  gap: 30px;
}

.dashboard-grey-card {
  width: 300px;
  padding: 15px 20px;
  color: $primary-color;
  background-color: $background-color;
  border-radius: 10px;
  -webkit-box-shadow: 3px 5px 4px 1px $field-color;
  -moz-box-shadow: 3px 5px 4px 1px $field-color;
  box-shadow: 3px 5px 4px 1px $field-color;

  a {
    text-decoration: none;
  }
}

.dashboard-card__total {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-card__total-icon {
  color: $primary-color;
}

.dashboard-card__total-text {
  font: $header-3 $font-medium;
  color: $border-color;
}

.dashboard-card__title {
  font: $header-1 $font-medium;
}

.dashboard-card_count-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font: $header-1 $font-medium;

  .material-icons-outlined {
    font-size: $h1;
  }
}

.dashboard-table {
  margin: 15px 0 30px 0;
}

@media only screen and (max-width: 720px) {
  .dashboard-grey-card {
    width: 200px;
  }
}

@media only screen and (max-width: 520px) {
  .dashboard-grey-card-container {
    gap: 20px;
  }
  .dashboard-grey-card {
    width: 135px;
  }
  .dashboard-card__title {
    font: $header-3 "SourceSansPro-SemiBold", sans-serif;
  }
  .dashboard-card_count-icon .material-icons-outlined {
    font-size: $header-1;
  }
}
