.otp-container {
  display: flex;
  gap: 10px;
  margin-left: 5%;
}

.otp-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 10px;
  font: $header-3 $font-regular;
  color: $field-color;
  border-radius: 10px;
  border: 1px solid $border-color;
  overflow: hidden;
  text-align: center;
}

.focused-otp-input {
  border-color: red;
}
