.input-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  min-height: 40px;
  background-color: $white-color;
  border: 1px solid $border-color;
  border-radius: 8px;

  .input-label {
    position: absolute;
    left: 8px;
    right: 8px;
    top: 9px;
    // max-width: calc(100% - (2 * 8px));
    max-width: max-content;
    pointer-events: none;
    font: $body-text $font-regular;
    color: $placeholder-color;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: 250ms;
    user-select: none;
    background-color: $white-color;
  }

  &.input-has-value-or-focused {
    .input-label {
      top: -11px !important;
      right: unset;
      padding: 0 10px 0 5px;
      background: $white-color;
      font-size: $foot-note;
      color: $primary-color;
    }
  }

  &.input-not-focused-has-text input {
    color: $primary-color;
  }

  input {
    flex: 1;
    width: 100%;
    padding: 2px 8px 2px 8px;
    font: $body-text $font-regular;
    color: $field-color;
    background-color: $white-color;
    border: none;
    outline: none;
    white-space: nowrap;
    overflow: hidden;
    /* text-overflow: ellipsis;*/
    border-radius: 8px;
    height: 100%;
  }

  .suffix {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 5px;
    color: $border-color;
    cursor: pointer;
  }

  .no-cursor {
    cursor: default;
  }
}
.border-primary .input-container input {
  color: $primary-color !important;
  height: 100%;
}
.grayout.answer-input {
  color: $border-color;
  .input-label {
    color: $border-color;
  }
  input {
    color: $border-color;
  }
}

.grayout.input-has-value-or-focused {
  color: $border-color;
  .input-label {
    color: $border-color;
  }
  .symbols-outlined {
    color: $border-color;
  }
  input {
    color: $border-color;
    -webkit-text-fill-color: $border-color;
  }
}

.disabled-control input {
  font: $body-text $font-regular !important;
}

textarea {
  padding: 2px 5px 2px 10px;
  font: $body-text $font-regular;
  color: $field-color;
  background-color: $white-color;
  border: 0.063rem solid #e7ecf2;
  border-radius: 0.313rem;
  resize: none;
}

textarea:focus {
  outline: none;
}

.input-container.error {
  .input-label {
    color: $error-color;
    top: -8px;
    background: $background-color;
    font-size: 11px;
    transition: 250ms;
  }

  .input-1 {
    border: 2px solid $error-color;
  }
}

.input-container.border-error {
  .input-label {
    color: $error-color;
    top: -8px;
    font-size: 13px;
    transition: 250ms;
  }

  .input-1 {
    border: 2px solid $error-color;
  }
}

.top5px > span {
  margin-top: -5px;
}

.moveLeft {
  .symbols-outlined {
    margin-left: 8px;
    color: $primary-color;
  }

  input {
    padding: 2px;
  }
}

@media only screen and (max-width: 680px) {
  .input-container {
    .input-label {
      font: $foot-note $font-regular;
    }

    ::-webkit-input-placeholder {
      /* Edge */
      font: $foot-note $font-regular;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font: $foot-note $font-regular;
    }

    ::placeholder {
      font: $foot-note $font-regular;
    }
  }
}
