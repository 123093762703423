.quote-multi-fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > *:not(.question) {
    margin-bottom: 15px;
  }

  > .input-container,
  .custom-select-container {
    margin-bottom: 15px;
  }
}

.outlined-secondary-button.quote-button:hover {
  background-color: $white-color;
}

.answer-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.answer-input {
  min-width: 50%;
}

.answer-custom-select {
  width: 50%;
}

.big_custom_input {
  width: 80%;
}

.text-info {
  color: $field-color;
  font: $body-text $font-regular;
}

.small_width {
  width: 41%;
  text-align: center;
  font: $header-2 $font-medium;
}

.hidethis {
  display: none;
}

.divider-text {
  color: $primary-color;
  font: $header-2 $font-medium;
}

.quote-other-question {
  margin-top: 20px;
}

.quote-input-suffix {
  font-size: $body-text;
}

@media only screen and (max-width: 680px) {
  .answer-custom-select {
    width: 100%;
  }
  .small_width {
    width: 100%;
  }
  .answer-input {
    min-width: 100%;
  }
}
