@import "../src/styles/utility";
@import "../src/common/common-component-combined";
@import "../src/styles/screens";

#root {
  height: 100%;
}
html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: var(--app-height);
  position: relative;
}
