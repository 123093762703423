@import "../../../../styles/color-palette";

$container_width: 280px;

.quickAccesscontainer > * {
  pointer-events: all;
}

.quickAccesscontainer {
  position: fixed;
  right: 0px;
  top: 80px;
  bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  transition: right 1s cubic-bezier(0.82, 0.085, 0.395, 0.895), background-color 1s ease;
  .arrow {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $secondary-color;
    color: $primary-color;
    border-radius: 10px 0px 0px 10px;
  }
  .arrow * {
    cursor: default;
  }
  .container {
    background-color: $white-color;
    box-shadow: 0 0 8px #999;
    border-radius: 10px 0px 0px 10px;
    max-height: 100%;
    overflow: auto;
    width: $container_width;
    .content {
      min-height: 100px;
      .label {
        cursor: pointer;
        font-weight: bold;
        font-size: 18px;
        color: $primary-color;
      }
      .section {
        padding: 16px 20px;
      }
      .active {
        background-color: $secondary-color;
      }
      .disabled * {
        opacity: 0.4;
        cursor: not-allowed !important;
      }
    }
  }
}
.quickAccesscontainer.inactive {
  right: -$container_width;
}
.quickAccesscontainer.active {
  right: 0px;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  transition: opacity 1s ease;
}
.backdrop.active {
  opacity: 0.4;
  background-color: #000;
}
.backdrop.inactive {
  opacity: 0;
  background-color: #000;
}
@media only screen and (max-width: 400px) {
  .quickAccesscontainer {
    position: fixed;
    top: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-top: 70px;
    .container {
      border-radius: 0px 0px 0px 10px;
      box-shadow: none;
    }
  }
}
