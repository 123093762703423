.rate-card-screen {
  width: 100%;
  height: 100%;
}

.empty-wrapper {
  height: 84px;
  background-color: $white-color;
}

.rate-card-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: calc(var(--app-height) - 200px);
  overflow: auto;
}

.rate-filters {
  width: 100%;
  display: none;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  font: $header-2 $font-medium;
  color: $primary-color;
  flex-wrap: wrap;
  padding: 10px;
}

.rate-mobile-filters {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font: $header-2 $font-medium;
  color: $primary-color;
  flex-wrap: wrap;
}
.rate-card-main-img {
  width: 120px;
  margin: 0 auto;
}
.summary {
  padding: 10px;
  display: none;

  .summary-title {
    font: $header-2 $font-medium;
  }
}

.summary-main {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 10px;

  .summary-box {
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    gap: 10px;

    .summary-icon {
      padding: 3px;
      color: $primary-color;
      border-radius: 15px;
    }

    :last-child {
      font: $body-text $font-medium;
      color: $primary-color;
    }
  }
}

.rate-card-container {
  display: none;
  margin: auto;
  width: 90%;
  padding: 10px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  p {
    margin: 0;
  }

  .rec {
    .rec-slider-container {
      margin: 0 5%;
    }

    .rec-arrow {
      font-size: 36px !important;
      color: #999 !important;
      z-index: 999 !important;

      :hover {
        color: #999 !important;
      }
    }
  }
}

.rec.rec-slider-container {
  overflow: visible;
}

.rate-card {
  min-width: 300px;
  max-width: 300px;

  .rate-card-main {
    padding: 20px 20px 24px 20px;
    border-radius: 6px;

    .rate-card-image {
      margin-top: 28px;
      max-width: 100%;
    }
  }

  .rate-card-sub {
    border-radius: 6px;

    .properties {
      .properties-title {
        background-color: $help-color;
        padding: 9px 0;

        p {
          font: $header-2 $font-medium;
          color: $white-color;
        }
      }
    }

    > *:first-child {
      margin-top: 0;
    }

    .rate-card-info {
      padding: 0 5px;
    }
  }

  .rate-card-info {
    margin-top: 27px;

    .info-title {
      color: $field-color;
      font: 12px $font-regular;
    }

    .info-description {
      font: 23px $font-medium;
    }
  }
}

.rate-card-mobile {
  position: relative;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }

  .rate-accordion {
    .accordion-container {
      position: fixed;
      width: 100%;
    }

    .accordion-item-header-container {
      background-color: $primary-color;
      color: $white-color;
      border-radius: 0;
      max-height: 80px;

      > div {
        width: 100%;
      }
    }

    .accordion-body-container {
      border: none;

      :last-child {
        border-bottom: none;
      }
    }

    .rate-mobile-header-card,
    .rate-mobile-cards {
      padding: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font: $body-text $font-medium;
      gap: 10px;
    }

    .rate-mobile-cards {
      padding: 18px 20px;
      background-color: #e5e9fd;
      border-bottom: 1px solid #97a2d9;
      color: $primary-color;
    }

    .rate-sub-head {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .mobile-rate-card-info {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .rate-card {
      margin-top: 80px;
      margin-bottom: 10px;
      min-width: 100%;
      width: 100%;
    }
  }
}

.rec-arrow-right,
.rec-arrow-left {
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.applicationEdited {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5e9fd;
  color: $primary-color;
  padding: 15px;

  b {
    font: 16px $font-medium;
  }
}

.rate-card-apply-container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 50%;
  padding: 10px 0 10px 0;
  transform: translateX(-50%);
}

.rate-card-apply-btn {
  width: 80%;
}

.rate-filter-quick-access {
  top: 60px !important;
  right: 0;
  bottom: unset !important;

  .arrow {
    top: 20px;
  }
}

.rate-summary-quick-access {
  top: 60px !important;
  left: -280px;
  right: unset !important;
  bottom: unset !important;
  transition: left 1s cubic-bezier(0.82, 0.085, 0.395, 0.895);

  .arrow {
    top: 20px;
    left: unset;
    right: -44px;
    border-radius: 0 10px 10px 0;
  }

  .container {
    border-radius: 0 10px 10px 0;
    width: 280px;
  }

  &.inactive {
    right: -280px;
  }

  &.active {
    left: 0;
  }
}

.rate-see-all-label {
  color: $white-color;
  font: $header-2 $font-medium;
  display: flex;
}

@media only screen and (min-width: 680px) {
  .rate-card {
    width: 100%;
    min-width: unset;
    // max-width: unset;
  }
  .rate-card-screen {
    padding: 15px 25px;
  }
  .rate-card-container,
  .summary {
    display: block;
  }
  .rate-card-mobile,
  .rate-filter-quick-access,
  .rate-summary-quick-access,
  .empty-wrapper {
    display: none;
  }
  .rate-card-apply-btn {
    margin-top: 20px;
  }
  .rate-card-main {
    background-color: $background-color;
  }

  .rate-card-sub {
    background-color: $background-color;
    border-radius: 6px;
    padding: 20px 0;
    margin-top: 15px;
  }

  .rate-card-wrapper {
    height: auto;
    overflow: unset;
  }

  .rate-filters {
    display: flex;
  }

  .summary-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    margin-top: 15px;

    .summary-box {
      background-color: #e5e9fd;
      width: max-content;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;

      .summary-icon {
        background-color: #283985;
        padding: 3px;
        color: #fff;
        border-radius: 15px;
      }

      :last-child {
        font: $body-text $font-medium;
        color: $primary-color;
        padding: 3px 10px 3px 0;
      }
    }
  }
}
