.auth-background {
  height: 100%;
  width: 100%;
  background-image: url("../../assets/images/backgroundImage.png");
  background-position: center;
  background-size: cover;
}

.form-white-container {
  display: flex;
  flex-direction: column;
  width: 525px;
  max-height: 95vh;
  margin-left: 10vw;
  padding: 40px 5vw;
  background-color: $white-color;
  border-radius: 0 0 15px 15px;
  overflow-y: auto;

  .custom-select__simple-placeholder {
    flex: 1;
  }
}

.form-logo {
  height: 50px;
}

.form-title-container {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.auth-title {
  margin: 10px 0;
  font: $header-1 $font-medium;
  color: $primary-color;
}

.form-toggle-text {
  align-self: flex-end;
  font: $foot-note $font-regular;
  color: $primary-color;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.form-checkbox {
  margin-top: 20px;

  label {
    font-size: $foot-note;
    color: $primary-color;
  }

  .checkmark {
    top: 4px;
  }
}

.form-button {
  min-width: 220px;
  margin-top: 20px;
}

.form-already-account-text {
  margin-top: 20px;
  font: $body-text $font-regular;
  color: $primary-color;

  span {
    color: $secondary-hover-color;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.remember-me-forgot-password-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-self: center;
  margin-top: 20px;

  * {
    font: $foot-note $font-regular;
    color: $primary-color;
  }

  a {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.back-to-login-text-container {
  width: 100%;
  text-align: right;
  text-decoration: underline;

  div {
    cursor: pointer;
  }
}

.otp-resend-button {
  width: 100%;
  text-align: center;
  margin-top: 30px;

  .otp-resend-button-disabled {
    background-color: $disable-color;
    color: $field-color;
    border-color: $disable-color;
  }
}

.otp-info-text {
  margin-bottom: 10px;
  font: $foot-note $font-regular;
  color: $primary-color;
}

.otp-info-text-password {
  font: 0.775rem $font-regular;
  color: $primary-color;
}

.otp-reset-text {
  text-align: center;
  margin: 10px 0;
  font: $foot-note $font-regular;
  color: $primary-color;
}
.no-padding {
  margin-top: 0px !important;
}
.small-width {
  width: 34%;
}
@media only screen and (max-width: 1500px) {
  .small-width {
    width: 50%;
  }
}
@media only screen and (max-width: 1300px) {
  .small-width {
    width: 50%;
  }
}
@media only screen and (max-width: 850px) {
  .small-width {
    width: 100%;
  }
}

@media only screen and (max-width: 680px) {
  .auth-background {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 100%;
    padding: 20px 0;
  }

  .auth-title {
    max-width: 290px;
  }

  .form-white-container {
    width: 90%;
    max-height: unset;
    margin: unset;
    border-radius: 15px;
  }
  .register-form-height {
    height: 86vh;
  }
  .small-width {
    width: 100%;
  }
}
