@import "color-palette";
@import "typography";
@import "common-class";
@import "basic-styles";
@import "modified-library";

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-default {
  cursor: default;
}

.f-14 {
  font-size: $foot-note;
}

.f-16 {
  font-size: $body-text;
}

.font-primary {
  color: $primary-color;
}

.font-underline {
  text-decoration: underline;
}

.font-secondary {
  color: $secondary-color;
}

.font-placeholder {
  color: $placeholder-color;
}

.font-field {
  color: $field-color;
}

.font-danger {
  color: $error-color;
}

.font-default {
  color: #000000;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none !important;
}

.just-center {
  justify-content: center;
}

.just-bet {
  justify-content: space-between !important;
}

.just-end {
  justify-content: flex-end !important;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.flex-1 {
  flex: 1;
}

.g-10 {
  gap: 10px;
}

.h-100 {
  height: 100%;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.gap-15 {
  gap: 15px;
}

.gap-5-w-50 {
  width: calc(50% - 5px);
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.ml-5 {
  margin-left: 0.313rem;
}

.ml-10 {
  margin-left: 0.625rem;
}

.ml-15 {
  margin-left: 0.938rem;
}

.mr-5 {
  margin-right: 0.313rem;
}

.mr-10 {
  margin-right: 0.625rem;
}

.mr-15 {
  margin-right: 0.938rem;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 0.625rem;
}

.mt-15 {
  margin-top: 0.938rem;
}

.mt-20 {
  margin-top: 1.25rem;
}

.mb-5 {
  margin-bottom: 0.313rem;
}

.mb-10 {
  margin-bottom: 0.625rem;
}

.mb-15 {
  margin-bottom: 0.938rem;
}

.mb-20 {
  margin-bottom: 20px;
}

.p-20 {
  padding: 20px;
}

.pl-10 {
  padding-left: 0.625rem;
}

.text-center {
  text-align: center;
}

.border-primary {
  border: 1px solid $primary-color !important;
}

.border-secondary {
  border: 1px solid $secondary-color !important;
}

.border-color-primary {
  border-color: $primary-color !important;
}

.border-color-secondary {
  border-color: $secondary-color !important;
}

.error-text {
  color: $error-color;
  font: $foot-note $font-regular;
}

.border-error {
  border: 1px solid $error-color !important;
}

.border-none {
  border: none !important;
}

.border-none {
  border: none !important;
}

.bg-white {
  background-color: #ffffff;
}

.bg-background-color {
  background-color: $background-color;
}

.bg-secondary-background-color {
  background-color: $secondary-background-color;
}

.form-title {
  width: 80px;
  font: $foot-note $font-medium;
  color: $primary-color;
}

.no-record-found {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 200px;
  padding: 20px;
  font: $body-text $font-regular;
  color: $primary-color;
}

@media only screen and (max-width: 680px) {
  .flex-direction-column {
    flex-direction: column;
  }
  .w-50 {
    width: 100%;
  }
}
