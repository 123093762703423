.equifax-container-register {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  color: $primary-color;
  font: 24px $font-medium;

  width: 48%;
  margin: 0 auto;
  padding: 5% 0px 0%;
}

.pad4 {
  padding: 3%;
}
.align-center {
  text-align: center;
}
.hidebtns {
  display: none;
}
.showbtns {
  display: contents;
}

.equifax-padding40 {
  padding: 15% 0% !important;
  text-align: center;
}
.centerbtn {
  margin: 0 auto !important;
  display: block;
}
.otp-resend-button {
  text-align: center;
  .otp-resend-button-disabled {
    background-color: $disable-color;
    color: $field-color;
    border-color: $disable-color;
  }
}
.country-select.disabled {
  pointer-events: none !important;
}
.daterangepicker {
  margin-top: 15px !important;
}
.daterangepicker .drp-selected {
  display: none !important;
}

.daterangepicker select.monthselect {
  color: #000000;
  border: 1px solid #000000;
  border-radius: 0;
}
.daterangepicker select.yearselect {
  color: #000000;
  border-radius: 0;
  border: 1px solid #000000;
}
.date-picker-container {
  width: 320px;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: $primary-color !important;
}
.below5px {
  margin-top: 2% !important;
  margin-left: 1% !important;
}
.mar4left {
  margin-left: 2%;
}
.equifax-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  color: $primary-color;
  font: 24px $font-medium;
  width: 48%;
  padding: 2% 0px 0%;
  margin: 0 auto;
  margin-bottom: 10%;
}

.three-inputs-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  width: 100%;
  padding: 0 4%;
}
.two-inputs-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  width: 100%;
  padding: 0 4%;
}
.one-inputs-row {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px;
  width: 100%;
  padding: 0 4%;
}
.form-button {
  margin-top: 15px !important;
  padding: 7px 30px;
}
.equifax-address {
  width: 100%;
}
.hidediv {
  display: none !important;
}
.showdivblock {
  display: block !important;
}
.showdiv {
  display: flex !important;
}
.button {
  padding: 7px 30px;
}
@media (max-width: 1024px) {
  .equifax-container {
    padding: 10px 10vw;
  }
  .three-inputs-row {
    grid-template-columns: 1fr;
    width: 100%;
  }
  .equifax-address {
    width: 100%;
  }
  .equifax-container-register {
    height: auto;
    width: 90%;
    padding: 17% 0px 0%;
  }
  .equifax-container {
    height: auto;
    width: 90%;
    padding: 2% 0px 0%;
  }
}
@media (max-width: 700px) {
  .three-inputs-row,
  .two-inputs-row,
  .one-inputs-row {
    grid-template-columns: 1fr;
    width: 100%;
    padding: 0;
  }
  .equifax-container-register {
    height: auto;
    width: 90%;
    padding: 17% 0px 0%;
  }
  .equifax-container {
    height: auto;
    width: 90%;
    padding: 3% 0px 0%;
  }
  .equifax-container {
    margin-bottom: 30%;
  }
  .three-inputs-row,
  .two-inputs-row,
  .one-inputs-row {
    gap: 15px;
  }
  .showdiv {
    display: grid;
  }
  .smallButton {
    width: 50%;
    margin: 10px auto;
  }
  .equifax-container {
    font-size: 19px;
  }
}
@media (max-width: 576px) {
  .equifax-container {
    padding: 0;
  }
  .three-inputs-row,
  .two-inputs-row,
  .one-inputs-row {
    grid-template-columns: 1fr;
    width: 100%;
  }
  .equifax-address {
    width: 100%;
  }
}
