.upload-document-dashed-container {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: $placeholder-color;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23787878FF' stroke-width='2' stroke-dasharray='6%2c 7' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  cursor: pointer;

  .material-icons-outlined {
    height: 26px;
  }
}

.border-rounded {
  border-radius: 10px;
  overflow: hidden;
}

.upload-document__name {
  padding-left: 10px;
  font: $body-text $font-regular;
  cursor: pointer;
}
