.pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  font: $body-text $font-regular;
  border: none;
  border-radius: 0 0 10px 10px;

  .pagination-custom-select {
    min-width: 65px !important;
    width: 65px !important;

    .custom-select {
      height: 35px;
      min-height: 35px;
      min-width: 65px;
      border: 1px solid #787878 !important;

      > *,
      span {
        color: $placeholder-color !important;
      }
    }
  }
}

.pagination-records-count {
  display: none;
}

.records-per-page-container {
  display: flex;
  align-items: center;
  color: $placeholder-color;
}

.pagination {
  display: flex;
  align-items: center;
  color: $placeholder-color;
}

.pagination-info-text {
  color: $field-color;
  font: $foot-note $font-regular;
}

.page-handler {
  display: flex;
  height: 35px;
  border: 1px solid $border-color;
  border-radius: 10px;
}

.first-page,
.last-page,
.prev-page,
.next-page,
.page-number {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 30px;
  padding: 0 5px;
  cursor: pointer;

  span {
    font-size: $body-text;
    color: $field-color;
  }

  :disabled {
    cursor: not-allowed !important;
  }
}

.prev-page {
  border-left: 1px solid $border-color;
}

.next-page {
  border-right: 1px solid $border-color;
}

.pagination-button-disabled {
  cursor: not-allowed;
}

.first-page span,
.last-page span {
  font-size: $header-2;
}

.page-number {
  font-size: $body-text;
  color: $field-color;
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  cursor: text;
}

.pagination-button-disabled span {
  cursor: not-allowed;
  opacity: 0.5;
}

@media only screen and (min-width: 680px) {
  .pagination-container {
    border: 1px solid #e5e9fd;
    padding: 20px;
  }

  .pagination-records-count {
    display: block;
  }

  .first-page,
  .last-page,
  .prev-page,
  .next-page,
  .page-number {
    min-width: 35px;
    padding: 0 10px;
  }

  .pagination-info-text {
    font: $body-text $font-regular;
  }
}
