.date-picker-container {
  position: relative;
  z-index: 1;
  input {
    background-color: transparent;
    z-index: 9;
    cursor: pointer;
  }

  .datepicker-icon {
    position: absolute;
    top: 10px;
    right: 11px;
    color: $border-color;
    font-size: 18px;
    cursor: pointer;
  }

  .react-datepicker__close-icon {
    z-index: 10;
  }

  &.range-date-picker {
    width: 250px;
  }
}
