.table-wrapper {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  border-radius: 10px 10px 0 0;
}

.table-class {
  position: relative;
  width: calc(100% - 1px);
  border-collapse: collapse;
  background-color: #e5e9fd;
  border-bottom: 1px solid $background-color;
  border-radius: 10px;
  z-index: 1;

  th {
    max-width: 350px;
    height: 2.5rem;
    top: 0;
    padding: 16px 20px;
    font: $header-3 $font-medium;
    color: $primary-color;
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: #e5e9fd;
    z-index: 2;
  }

  th.table-small-header {
    max-width: 100%;
    width: 250px;
  }

  th.table-big-header {
    max-width: 100%;
    width: 400px;
  }

  th:first-child {
    border-top-left-radius: 15px;
  }

  th:last-child {
    border-top-right-radius: 15px;
  }

  tbody {
    height: calc(100% - 9rem);
    box-shadow: 0 0 0 1px white;
    overflow-y: auto;
  }

  td {
    max-width: 350px;
    height: 2.5rem;
    padding: 16px 20px;
    font: $body-text $font-regular;
    color: $field-color;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:first-child {
      border-left: 1px solid #e5e9fd;
    }

    &:last-child {
      border-right: 1px solid #e5e9fd;
    }

    .link {
      color: $field-color;
      text-decoration: underline;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    a {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  tr:nth-child(odd) td:not(:last-child) {
    border-right: 0.095rem solid $secondary-background-color;
  }

  tr:nth-child(even) td:not(:last-child) {
    border-right: 0.095rem solid $white-color;
  }

  td.fixed-action-menu {
    overflow: visible;
  }

  .fixed-action-menu {
    position: sticky;
    right: 0;
    color: $placeholder-color;

    .material-icons-round:hover {
      color: $field-color;
    }
  }

  .fixed-action-menu-clicked {
    z-index: 1;
  }
}

.main-list-table {
  th {
    background-color: $background-color;
  }
}

.table-action-buttons {
  display: flex;
  align-items: center;
  justify-content: center;

  button:not(:last-child) {
    margin-right: 7px;
  }
}

.editable-drawer-field {
  display: flex;
  align-items: center;

  .date-picker-container {
    position: relative;
    max-width: 180px;

    .datepicker-icon {
      position: absolute;
      top: 10px;
      right: 11px;
      color: $border-color;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .react-select-container {
    min-width: 180px;
  }
}

.info-button {
  cursor: pointer;

  &:hover {
    color: $primary-color;
  }
}

.drawer-edit-button {
  margin: 25px 25px 10px 25px;
}

.table-buttons {
  display: flex;
  align-items: center;
  gap: 10px;

  .button {
    margin: 0px 10px;
    font: $body-text $font-medium;
    padding: 7px 15px;
    min-width: 150px;
  }
}

@media only screen and (max-width: 680px) {
  .quote-table .table-class,
  .dashboard-table .table-class {
    display: none;
  }
  .table-buttons {
    .button {
      margin: 0 0;
    }
  }
}

@media only screen and (max-width: 1080px) {
  .table-class th {
    padding: 14px 15px;
    font-size: $body-text;
  }
  .table-class td {
    padding: 14px 15px;
  }
}
