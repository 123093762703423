.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  padding: 10px 30px;
  font: $header-2 $font-medium;
  color: #ffffff;
  border-radius: 10px;
  border: 1px solid $primary-color;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
}
.white-text {
  color: #ffffff !important;
}
.marginRight10 {
  margin-right: 10px;
}
.primary-button {
  background-color: $primary-color;
  border-color: $primary-color;
}

.primary-button:hover {
  background-color: $primary-hover-color;
  border-color: $primary-hover-color;
}

.secondary-button {
  color: $primary-color;
  background-color: $secondary-color;
  border-color: $secondary-color;
}

.secondary-button:hover {
  background-color: $secondary-hover-color;
  border-color: $secondary-hover-color;
}

.button:disabled {
  cursor: not-allowed;
}

.outlined-primary-button {
  background-color: transparent;
  color: $primary-color;
  border-color: $primary-color;
}

.outlined-secondary-button {
  background-color: #ffffff;
  color: $primary-color;
  border-color: $secondary-color;
}

.outlined-primary-button:hover {
  background-color: $primary-color;
  color: $white-color;
}

.outlined-secondary-button:hover {
  background-color: $secondary-color;
}

.slim-button {
  padding: 5px 20px;
  font: $body-text $font-regular;
}

.success-button {
  background-color: $success-color;
  border-color: $success-color;
}
.success-button.disable {
  background-color: $disable-color;
  border-color: $disable-color;
}
.success-button:hover {
  background-color: $success-hover-color;
  border-color: $success-hover-color;
}
.success-button.disable:hover {
  background-color: $disable-color;
  border-color: $disable-color;
}

.error-button {
  background-color: $error-color;
  border-color: $error-color;
}

.error-button:hover {
  background-color: $error-hover-color;
  border-color: $error-hover-color;
}

.button-loader {
  border: 3px solid grey;
  border-radius: 50%;
  border-top: 3px solid #fff;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
}
.disable {
  cursor: not-allowed !important;
}
.a-tag-button {
  background-color: transparent;
  color: $primary-color;
  font: $header-2 $font-medium;
  border-color: $primary-color;
  text-decoration: none;
  padding: 10px 30px;
  border-radius: 10px;
  height: fit-content;
  border: 1px solid $primary-color;
  cursor: pointer;
  white-space: nowrap;
}
.a-tag-button:hover {
  background-color: $primary-color;
  color: $white-color;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 680px) {
  .button {
    font: $body-text $font-medium;
  }
  .footer-container > button.outlined-primary-button {
    margin-left: -10px;
    margin-right: 10px;
  }
  .footer-container > .prev-next-button-container > .button.icon-button {
    padding: 9px;
  }
  .outlined-primary-button:active {
    background-color: $primary-color;
    color: $white-color;
  }
  .outlined-primary-button:hover {
    background-color: transparent;
    color: $primary-color;
  }
  .a-tag-button:hover {
    background-color: transparent;
    color: $primary-color;
  }
}
