.react-datepicker-popper {
  z-index: 9999;
}

.react-datepicker__close-icon {
  right: 30px;

  &::after {
    background-color: $border-color;
  }
}
.react-datepicker-wrapper {
  display: block;
}
