.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  font: $body-text $font-medium;
  color: $primary-color;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0px auto;
}

.checkbox-container-equifax {
  align-items: center;
  position: relative;
  padding-left: 35px;
  font: $body-text $font-medium;
  color: $primary-color;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0px auto;
}

.align-left {
  text-align: left !important;
}

/* Hide the browser's default checkbox */
.checkbox-container-equifax input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.checkbox-container input:disabled ~ .checkmark {
  cursor: not-allowed;
}

.checkbox-container-equifax input:disabled ~ .checkmark {
  cursor: not-allowed;
}

.checkbox-container:hover input:disabled ~ .checkmark {
  background-color: $white-color;
}

.checkbox-container-equifax:hover input:disabled ~ .checkmark {
  background-color: $white-color;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: $white-color;
  border-radius: 3px;
  cursor: pointer;
  border: 2px solid $primary-color;
}

/* On mouse-over, add a grey background color */
.checkbox-container-equifax:hover input ~ .checkmark {
  background-color: $background-color;
}

.checkbox-container:hover input ~ .checkmark {
  background-color: $background-color;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark,
.checkbox-container:hover input:disabled:checked ~ .checkmark {
  background-color: $primary-color;
}

.checkbox-container-equifax input:checked ~ .checkmark,
.checkbox-container-equifax:hover input:disabled:checked ~ .checkmark {
  background-color: $primary-color;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container-equifax input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 4px;
  width: 6px;
  height: 11px;
  border: solid $white-color;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-container-equifax .checkmark:after {
  left: 4px;
  width: 6px;
  height: 11px;
  border: solid $white-color;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-container-equifax a {
  color: $primary-color !important;
}
