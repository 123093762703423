$primary-color: #283985;
$primary-hover-color: #061661;
$light-primary-color: rgba(40, 57, 133, 0.5);
$secondary-color: #f9cc15;
$secondary-hover-color: #fbb040;
$success-color: #42ba96;
$success-hover-color: #17946e;
$error-color: #bf4547;
$error-hover-color: #8b1618;
$border-color: #787878;
$placeholder-color: #a0a0a0;
$disable-color: #bababa;
$help-color: #acacac;
$background-color: #f0f0f0;
$secondary-background-color: #f5f7f9;
$white-color: #ffffff;
$black-color: #000000;
$field-color: #787878;
