.loaderSmall,
.loaderSmall:before,
.loaderSmall:after {
  background: #000;
  -webkit-animation: loadSmall1 1s infinite ease-in-out;
  animation: loadSmall1 1s infinite ease-in-out;
  width: 1em;
  height: 10px;
}

.loaderSmall {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%);
  color: #000;
  text-indent: -9999em;
  font-size: 4px;
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loaderSmallRelative {
  position: relative;
  top: 40%;
  left: 50%;
  transform: translate(-50%);
  color: #000;
  text-indent: -9999em;
  font-size: 4px;
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loaderSmall:before,
.loaderSmall:after {
  position: absolute;
  top: 0;
  content: "";
}

.loaderSmall:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loaderSmall:after {
  left: 1.5em;
}

@-webkit-keyframes loadSmall1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 10px;
  }
  40% {
    box-shadow: 0 -2em;
    height: 15px;
  }
}

@keyframes loadSmall1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 10px;
  }
  40% {
    box-shadow: 0 -2em;
    height: 15px;
  }
}
