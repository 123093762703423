@font-face {
  font-family: "SourceSansPro-Regular";
  src: local("SourceSansPro-Regular"), url(../assets/fonts/SourceSansPro-Regular.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "SourceSansPro-SemiBold";
  src: local("SourceSansPro-SemiBold"), url(../assets/fonts/SourceSansPro-SemiBold.ttf) format("truetype");
  font-weight: normal;
}

$h1: 3.75rem; //60px
$font-regular: "SourceSansPro-Regular", sans-serif;
$font-medium: "SourceSansPro-SemiBold", sans-serif;

$header-1: 1.75rem; //28px
$header-2: 1.25rem; //20px
$header-3: 1.125rem; //18px
$body-text: 1rem; //16px
$foot-note: 0.875rem; //14px
$caption-text: 0.75rem; //12px
