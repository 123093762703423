.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.25);
  background-blend-mode: screen;
  transition: all 0.3s ease-in-out;
  z-index: 10;
}

.drawer-container {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 350px;
  width: 100%;
  height: 100%;
  background-color: white;
  transition: all 0.5s ease-in-out;
  box-shadow: 0 0 30px #7272724d;
  overflow: hidden;
  z-index: 11;
  transform: translate3d(100%, 0, 0);
}

.drawer-opened {
  transform: none;
}

.drawer-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  min-width: 100%;
  padding: 20px 40px 20px 20px;
  font: $header-2 $font-medium;
  color: $primary-color;
  background-color: $secondary-color;

  * {
    width: fit-content !important;
    overflow: hidden;
    white-space: nowrap;
  }
}

.close-drawer {
  padding: 3px;
  font-size: 18px;
  color: $secondary-color;
  background-color: $primary-color;
  border-radius: 50%;
  cursor: pointer;
}

.drawer-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 75px);
  overflow-y: auto;
}

.drawer-grid {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 20px 10px;
  font: $body-text $font-medium;
  color: $primary-color;
  padding: 20px;
  overflow: hidden;

  .title {
    font-family: $font-medium;
    color: $field-color;
  }
}

@media only screen and (min-width: 680px) {
  .drawer-container {
    top: 75px;
    min-width: 450px;
    height: calc(var(--app-height) - 75px);
    width: auto;
    border-radius: 10px 0 0 0;
  }
}
