.layout-wrapper {
  width: 100vw;
  height: var(--app-height);
  overflow: hidden;
}

.page-container {
  height: calc(var(--app-height) - 65px);
  overflow: auto;
}

.page-container-padding {
  padding: 15px 25px;
}

.mt-70 {
  margin-top: 70px;
}

.login-page-container {
  height: var(--app-height);
}

@media only screen and (min-width: 680px) {
  .page-container {
    height: calc(var(--app-height) - 75px) !important;
  }
}
