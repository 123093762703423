.profile-wrapper {
  margin: 15px auto 0;
  width: 500px;
  padding: 1%;
  text-align: center;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  .input-field-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .view-profile-row {
    padding: 2px 5px;
    width: 100%;
    display: flex;
    font: $header-3 $font-medium;
    gap: 10px;

    :first-child {
      text-align: left;
      min-width: 150px;
      width: 100px;
      color: $field-color;
    }

    :last-child {
      width: 100%;
      color: $primary-color;
      text-align: right;
      overflow: hidden;
      white-space: break-spaces;
      word-break: break-word;
    }
  }

  input {
    color: $primary-color;
  }

  .input-container {
    width: 100%;
  }
}
.back-save-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

@media only screen and (max-width: 680px) {
  .profile-wrapper {
    width: 100%;

    .view-profile-row {
      font: $body-text $font-medium;
    }
  }
  .back-save-buttons {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
  }
}
