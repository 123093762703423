.header-wrapper {
  color: $primary-color;
  background-color: $background-color;
  z-index: 99999;
  width: 100%;
}

.header-container {
  position: relative;
  z-index: 9;
  color: $primary-color;
  background-color: $background-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}

.logo-login-button {
  padding: 0 10%;
}

.user-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}

.user-name {
  display: flex;
  align-items: center;
  margin-right: 15px;
  padding: 5px 10px;
  font: $header-3 $font-medium;
  color: $primary-color;
  cursor: pointer;
  border-radius: 12px;

  &:hover {
    background-color: $white-color;
  }

  span {
    margin-left: 10px;
  }
}

.user-settings {
  position: absolute;
  top: 75px;
  right: 30px;
  width: 245px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 30px #82828229;
  z-index: 9999;

  > div {
    position: relative;
    padding: 10px 0;

    &::after {
      content: "";
      position: absolute;
      top: -20px;
      left: 86%;
      display: block;
      width: 0;
      border-style: solid;
      border-width: 0 0 20px 20px;
      border-color: transparent transparent $white-color transparent;
      z-index: 1;
    }

    > div {
      display: flex;
      align-items: center;
      padding: 8px 25px;
      font: $header-3 $font-medium;
      color: $primary-color;
      cursor: pointer;

      span {
        margin-right: 10px;
        font-size: 24px;
        color: $secondary-color;
      }
    }
  }
}

.user-settings > div > div:hover {
  background-color: $background-color;
}

//change password modal

.change-password-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  width: 100%;

  input {
    min-width: 200px;
  }

  .otp-box {
    min-width: 40px;
  }
}

@media only screen and (max-width: 680px) {
  .header-container {
    height: 60px;
  }
}
