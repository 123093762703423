.progress {
  background-color: $background-color;
  width: 100%;
  height: 5px;
  padding: 0 !important;
  margin: 0 auto;
  border: none;
  display: block;
  z-index: 9;
}

.progress-done {
  position: relative;
  z-index: 9;
  background: $primary-color;
  border-radius: 0px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}
