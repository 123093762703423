.table-mobile-container {
  width: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    color: $primary-color;
    font: $header-3 $font-medium;
  }

  .accordion-item-header-container {
    width: 100%;
    background: $secondary-background-color;

    > div:first-child {
      width: 100%;
    }
  }

  .table-mobile-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $field-color;
    font: $body-text $font-medium;

    .link {
      color: $field-color;
      text-decoration: underline;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .active-accordion-header {
    background: $primary-color;

    .table-mobile-header {
      color: $white-color;

      .link {
        color: $white-color;
      }
    }
  }

  .accordion-item-wrapper {
    margin-bottom: 10px;
  }

  .accordion-body-container {
    padding: 0;

    > *:first-child {
      padding: 0;
    }

    .mobile-accordion-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      font: $body-text $font-medium;

      .row-label {
        color: $primary-color;
      }

      .row-value {
        color: $field-color;
      }
    }
  }
}

@media only screen and (min-width: 680px) {
  .table-mobile-container {
    display: none;
  }
}
