.quote-tool-container {
  width: 100%;
  height: calc(var(--app-height) - 130px);

  .quote-tool-form {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100% - 44px);
    overflow: scroll;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 10%;

    .quote-tool-form-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
}

.ballon-dollar-sign {
  padding-left: 8px;
  color: $field-color;
}

.abn-lookup-modal {
  .table-class {
    background-color: #fff;

    th,
    td {
      padding: 5px 20px;
      border-radius: 0;
    }

    tr:nth-child(even) td:not(:last-child) {
      border-right: 0.095rem solid $background-color;
    }
  }

  .modal-body,
  .modal-footer {
    padding: 0;
  }
}

/* quote list */
.page-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quote-table {
  margin: 20px 0;
}

.quote-error-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $error-color;
  font: $foot-note $font-regular;
  margin: 10px 0px;
  width: 100%;
}

.quote-active-saving {
  display: flex;
  align-items: center;
  color: $primary-color;
  font-size: $caption-text;

  > span {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 680px) {
  .quote-tool-container {
    height: calc(var(--app-height) - 165px);
  }

  //.quote-tool-form-content {
  //  width: 80% !important;
  //}
}

@media only screen and (min-width: 1260px) {
  //.quote-tool-form-content {
  //  width: 60% !important;
  //}
}

@media only screen and (max-width: 680px) {
  .page-header-row {
    display: inline !important;
  }
}
