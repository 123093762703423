.footer-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  padding: 0 10%;
  background-color: $background-color;
}

.prev-next-button-container {
  display: flex;
  gap: 12px;

  .button.icon-button {
    padding: 4px !important;
  }
}

@media only screen and (min-width: 680px) {
  .footer-container {
    height: 90px;
  }
  .prev-next-button-container {
    .button.icon-button {
      padding: 8px !important;
    }
  }
}
