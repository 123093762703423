html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $font-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

input::placeholder,
textarea::placeholder,
.react-select__placeholder {
  font: $body-text $font-regular;
  color: $placeholder-color !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s;
  -webkit-text-fill-color: $primary-color;
}

/* scrollbar starts here */
* {
  scrollbar-color: $primary-color white;
  scrollbar-width: thin;
}

/* width */
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-hover-color;
}

/* scrollbar ends here */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.Toastify__toast {
  min-height: 40px !important;
}

.Toastify__toast-body {
  padding: 3px !important;
}

.Toastify__toast-theme--light {
  color: $primary-color !important;
  font-size: $foot-note !important;
}

.align-center {
  text-align: center;
}

.noGrid.gapBetweenDiv div {
  padding: 0.5%;
}

.display-in-grid {
  display: grid;
  grid-column-start: 1;
  grid-column-end: 2;
}
/* IOS */
input[type="text"][disabled] {
  -webkit-text-fill-color: $primary-color;
  opacity: 1; /* required on iOS */
}
