.contact-number-container {
  display: flex;
  flex: 1;
  height: 40px;
  min-height: 40px;
  border-radius: 10px;
  border: 1px solid $border-color;
  overflow: hidden;

  > * {
    height: 100%;
  }
  .input-container {
    border: 0px;
    flex: fit-content;
  }
}
.contact-number-container.border-error {
  .custom-select {
    border-right: 1px solid $error-color !important;
  }
}

.country-select .custom-select {
  width: 100px;
  border-left: none;
  border-top: none;
  border-bottom: none;
}

.country-input {
  border: none;
}

.country-code-select-focused .custom-select {
  border-color: $secondary-color;
}

@media only screen and (max-width: 680px) {
  .contact-number-container {
    flex: auto;
    overflow: visible;
    .country-select {
      width: 27%;
    }
    .input-container {
      // width: 40%;
      min-height: auto;
    }
    .custom-select {
      background-color: transparent;
    }
  }
}
